import React from 'react';
import XtraTout from '../home/XtraTout';

function XtraContent() {
  return (
    <XtraTout
      bgColor="#0e0338"
      scrollOffset={[-20, 20]}
      textColor="#fff"
    />
  )
}

export default XtraContent;