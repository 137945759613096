import React from 'react';
import XtraToutMobile from '../home/XtraToutMobile';

function XtraContentMobile() {
  return (
    <XtraToutMobile
      bgColor="#0e0338"
      scrollOffset={[-20, 20]}
      textColor="#fff"
    />
  )
}

export default XtraContentMobile;