import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import '../scss/xtra.scss';
// hooks
import useMediaQuery from '../hooks/useMediaQuery';
// utils
import handlePageTransition from '../lib/handle-page-transition';
// components
import SEO from '../components/SEO';
import XtraContent from '../components/xtra/XtraContent';
import MobileXtraContent from '../components/xtra/XtraContentMobile';

function XtraPage({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  return (
    <div>
      <SEO title="XTRA | Falkon Content" />
      <div className="xtra" style={{ overflowX: 'hidden' }}>
        {isDesktop
          ? (<XtraContent />)
          : (<MobileXtraContent />)
        }
      </div>
    </div>
  );
}

export default React.memo(XtraPage);
